<script setup lang="ts">
import { computed } from 'vue'
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { useWorkspaceStore } from '@/store/workspace'
import { useCollabStore } from './store'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { ref } from 'vue'
import TiptapEditor from '@/components/common/tiptap/TiptapEditor.vue'
import { Button, Popup } from '@/components/common'
import { showToast } from '@/utils'
import { useApplicationStore } from '@/store'
import MembersPreview from './components/MembersPreview.vue'
import { ISODate } from '@/utils/helpers'
import Confirm from '@/components/common/Confirm.vue'
import { Asset } from './models/asset'
import AccessDenied from './components/AccessDenied.vue'
import ManageMembers from './components/ManageMembers.vue'
import User from '@/models/user'


const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore
const store = useCollabStore()
const appStore = useApplicationStore()
const router = useRouter()

const membersPopup = ref<InstanceType<typeof Popup> | null>(null)
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)

const fileId = ref(Number(router.currentRoute.value.params.fileId))

const loaded = ref<boolean>(false)
const content = ref<string>('')
const accessDenied = ref<InstanceType<typeof Popup> | null>(null)

let debounceTimeout: ReturnType<typeof setTimeout> | null = null

store
  .loadAsset(fileId.value)
  .then((response) => {
    content.value = response.content ?? ''
    loaded.value = true
  })
  .catch(() => accessDenied.value?.show())

watch(
  () => router.currentRoute.value.params.fileId,
  () => {
    fileId.value = Number(router.currentRoute.value.params.fileId)

    if (!store.asset || store.asset.id !== fileId.value) {
      store
        .loadAsset(fileId.value)
        .then((response) => {
          content.value = response.content ?? ''
          loaded.value = true
        })
        .catch(() => accessDenied.value?.show())
    }
  },
)

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Collab`,
    url: { name: 'Collab' },
  },
  {
    title: store?.asset?.title ?? '',
    url: { name: 'CollabFile', params: { fileId: fileId.value }},
  },
])

const saveContent = () => {
  if (content.value) {
    store.updateAsset(fileId.value, { content: content.value })
  }
}

watch(content, () => {
  if (!loaded.value || store.asset?.content === content.value || content.value === '<p></p>' || !content.value || !store.asset?.content) {
    return
  }

  if (debounceTimeout) clearTimeout(debounceTimeout)
  debounceTimeout = setTimeout(() => {
    saveContent()
  }, 1000)
})

const handleCopyLink = () => {
  navigator.clipboard.writeText(window.location.href).then(() => {
    showToast({ text: 'Task URL copied to clipboard', type: 'success' })
  })
}

const toggleFavorite = () => {
  store.toggleFavorite(store.asset as Asset, !store.asset?.isFavorite)
}

const initDelete = () => {
  confirmPopup.value?.show({
    confirmString: 'delete',
    title: `Delete file`,
    message: `You’re about to delete file. Are you sure? This is permanent action and can not be undone. Please, confirm your purpose by typing “delete” in the input below.`,
    actions: [
      {
        title: 'Cancel',
        theme: 'neutral',
        variant: 'ghost',
      },
      {
        title: `Delete`,
        theme: 'danger',
        isConfirm: true,
        handler: async () => {
          await store.deleteAsset(fileId.value)
          router.push({ name: 'CollabHome' })
        },
      },
    ],
  })
}
</script>
<template>
  <Page>
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />

      <div :class="$style.pageActions">
        <Button
          size="2"
          variant="ghost"
          :class="store.asset?.isFavorite ? $style.isFavorite : $style.notFavorite"
          theme="neutral"
          :icon="store.asset?.isFavorite ? 'solidStar' : 'star'"
          @click="toggleFavorite"
        />

        <Button
          size="2"
          variant="ghost"
          theme="danger"
          icon="trashCan2Outline"
          @click="initDelete"
        />

        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          icon="chainLink"
          @click="handleCopyLink"
        />
      </div>
    </template>

    <Popup
      ref="accessDenied"
      title="Access denied"
      hide-close-button
      persistent
    >
      <AccessDenied type="file" />
    </Popup>

    <Popup ref="membersPopup" title="Members">
      <ManageMembers
        :members="store.asset?.members ?? []"
        @add="(member: User) => store.addAssetMember(store.asset?.id as number, member)"
        @remove="(member: User) => store.removeAssetMember(store.asset?.id as number, member)"
        :empty-text="`Nobody has access to this ${store.asset?.type} yet. Invite workspace members to give the access to this ${store.asset?.type}.`"
      />
    </Popup>

    <div v-if="loaded" :class="$style.container">
      <Confirm ref="confirmPopup" />
      <div>
        <div :class="$style.info">
          <p>
            Updated At {{ ISODate(store.asset?.updatedAt as string, { format: 'LLL dd, yyyy' }) }}
          </p>
          <MembersPreview
            :members="store.asset?.members ?? []"
            @click="membersPopup?.show()"
          />
        </div>
        <h2 :class="$style.title">{{ store.asset?.title }}</h2>
      </div>

      <TiptapEditor
        :identifier="fileId"
        :initial-content="store.asset?.content ?? ''"
        :current-user="appStore.user?.name"
        @update="(value) => (content = value)"
      />
    </div>
  </Page>
</template>

<style module lang="scss">
@import url(./components/common.module.scss);

.pageActions {
  display: flex;

  .isFavorite {
    color: var(--warning-9, #FFC53D);
  }

  .notFavorite {
    color: var(--neutral-12, #1E1F24);
  }
}

.container {
  width: 100%;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;

  .title {
    @extend .bold-9;
    margin-top: 8px;
  }

  .info {
    @extend .medium-3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--neutral-11);
  }
}
</style>
