<template>
  <bubble-menu v-if="editor" :editor="editor" />

  <editor-content :editor="editor" />
</template>

<script lang="ts" setup>
import * as Y from 'yjs'
import jsonwebtoken from 'jsonwebtoken'
import { onMounted, onUnmounted, watch } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import Heading from '@tiptap/extension-heading'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import BubbleMenu from '@/components/common/tiptap/BubbleMenu.vue'
import CodeBlock from '@tiptap/extension-code-block'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import Collaboration from '@tiptap/extension-collaboration'
import { TiptapCollabProvider } from '@hocuspocus/provider'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
// @ts-ignore
import Commands from './extensions/SlashCommand/commands.js'
// @ts-ignore
import suggestion from './extensions/SlashCommand/suggestion.js'
import { ImageUpload } from './extensions/ImageUpload/imageUpload'

const props = defineProps<{
  initialContent: string
  identifier: number
  currentUser?: string
}>()

const emit = defineEmits<{
  (e: 'update', content: string): void
}>()

const room = `document.${props.identifier}`

const ydoc = new Y.Doc()

const provider = new TiptapCollabProvider({
  name: room,
  document: ydoc,
  appId: import.meta.env.VITE_TIPTAP_APP_ID,
  token: jsonwebtoken.sign({}, import.meta.env.VITE_TIPTAP_SECRET),
})

const editor = useEditor({
  onCreate: ({ editor: currentEditor }) => {
    if (provider && !provider.isSynced) {
      provider.on('synced', () => {
        setTimeout(() => {
          if (currentEditor.isEmpty) {
            currentEditor.commands.setContent(props.initialContent)
          }
        }, 0)
      })
    } else if (currentEditor.isEmpty) {
      currentEditor.commands.setContent(props.initialContent)
      currentEditor.commands.focus('start', { scrollIntoView: true })
    }
  },
  extensions: [
    Bold,
    Italic,
    Strike,
    Underline,
    Color,
    TextStyle,
    CodeBlock,
    Link.configure({
      openOnClick: false,
      defaultProtocol: 'https',
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Highlight.configure({ multicolor: true }),
    Document,
    Paragraph,
    Text,
    Heading.configure({
      levels: [1, 2, 3],
    }),
    Commands.configure({
      suggestion,
    }),
    ListItem,
    OrderedList,
    BulletList,
    Image,
    ImageUpload,
    Collaboration.extend().configure({
      document: ydoc,
    }),
    CollaborationCursor.extend().configure({
      provider,
    }),
    Placeholder.configure({
      placeholder: "Enter text or type '/' for commands",
      includeChildren: false,
      showOnlyCurrent: true,
    }),
    BubbleMenu,
  ],
  onUpdate({ editor }) {
    emit('update', editor.getHTML())
  },
})

const statusHandler = ({ status }: { status: string }) => {
  console.log(status)
}

watch(editor, async (editor) => {
  if (editor && props.currentUser) {
    editor
      .chain()
      .focus()
      .updateUser({ name: props.currentUser, color: '#1e1f24' })
      .run()
  }
})

onMounted(() => {
  provider.on('status', statusHandler)

  provider.on('authenticationFailed', ({ reason }: { reason: string }) => {
    console.error('Authentication failed:', reason)
  })
})

onUnmounted(() => {
  provider.off('status', statusHandler)
})
</script>

<style lang="scss">
.tiptap {
  padding: 2rem 0rem;
  :first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  a {
    color: blue;
    cursor: pointer;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  pre {
    background: black;
    border-radius: 0.5rem;
    color: white;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  img {
    display: block;
    height: auto !important;
    margin: 0;
    width: 100% !important;

    &.ProseMirror-selectednode {
      outline: 3px solid var(--purple);
    }
  }

  p.is-editor-empty:first-child::before {
    color: var(--neutral-alpha-8);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .is-empty::before {
    color: var(--neutral-alpha-8);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
  }
}
</style>
